<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div class="page-header">
      <h1>
        <span>{{ $tc("user", 2) }} &ndash;</span>
        {{ $t("create") }}
      </h1>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <BackButton :parent-route="{ name: 'UserIndex' }"></BackButton>

        <UserForm :mode="'create'" @submit="submit"></UserForm>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BackButton from "../../components/BackButton";
import UserForm from "./partials/UserForm";

export default {
  name: "UserCreateForSuperAdmin",
  components: { BackButton, UserForm },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["createUser", "resetPasswordRequest"]),
    submit(payload) {
      this.loading = true;

      this.createUser(payload)
        .then(async (user) => {
          this.$buefy.toast.open({
            message: this.$t("user_created"),
            type: "is-primary",
          });

          this.resetPasswordRequest({
            emailAddress: user.emailAddress,
            isActivation: true,
          }).then(() => {
            this.$router.push({ name: "UserView", params: { id: user.id } });
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
